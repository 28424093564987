import RSeo from "@components/resultsseo"
import useUrlData from "@components/SearchResults/hooks/useUrlData"
import Seo from "@components/seo"
import { createResultsCanonicalUrl, getH1Text, getSeoDesc } from "@lib/searchUtils"
import "@styles/search-results.scss"
import SearchResultsPage from "@templates/search-results-template"
import React, { useEffect, useState } from "react"
const { useLocation } = require("@reach/router")
const SearchResults = (props) => {
  return <SearchResultsPage {...props} />
}

export const Head = (props) => {
  let { pathname } = useLocation()
  const pageUrlData = useUrlData()
  const h1Text = getH1Text(pageUrlData)
  const seoDesc = getSeoDesc(pageUrlData)
  const [customCanonical, setCustomCanonical] = useState(process.env.GATSBY_SITE_URL + props.location.pathname)

  useEffect(() => {
    const fetchCanonicalUrl = async () => {
      const canonicalUrl = await createResultsCanonicalUrl({ pageUrlData })
      setCustomCanonical(process.env.GATSBY_SITE_URL+canonicalUrl)
    }

    fetchCanonicalUrl()
  }, [pathname, h1Text])
  if (!customCanonical) {
    // You can return a loading state or default canonical URL here if needed
    return null
  }
  return  <>
  <link crossorigin href="https://d4vban0ooq-2.algolianet.com" rel="preconnect" />

  <RSeo 
  customCanonical={customCanonical || process.env.GATSBY_SITE_URL + props.location.pathname}
   title={h1Text} description={seoDesc} isproperty />
   </>
}

export default SearchResults
